import React from "react"
import Layout from "../components/layout"
import Jumbotron from 'react-bootstrap/Jumbotron'
import Coursecard from "../components/cramcourse-cards";

const Cramclass = ({ data }) => {
  return (
    <Layout>
    <Jumbotron className="bg-lightblue">
      <div class="container">
        <div style={{maxWidth:"600px",textAlign:"center",margin:"auto"}}>
          <h2>Cram Class</h2>
          <p className="mt-4">
          Growth-focused tech courses, carefully put together and conveniently delivered. You're only a course away from realizing your true career potential.
          </p>
        </div>
      </div>
    </Jumbotron>
      <div class="container">
        <div className="row">
          <div class="col-lg-12">
            <div class="emply-text-sec">
              <Coursecard></Coursecard>
            </div>
        </div>
        </div>
      </div>
      <hr className="container hrLight mt-4" />
    </Layout>
  )
}

export default Cramclass
